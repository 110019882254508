import { VStack, Text, Box, Button, chakra, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { LoadingIcon } from '@/components/Icons/originals';

interface ProgressContentProps {
  whatWaitFor: string;
}

const MotionIcon = chakra(motion.div);
const spin = keyframes`  
from {transform: rotate(0deg);}   
to {transform: rotate(360deg)} 
`;

const ProgressContent = ({ whatWaitFor }: ProgressContentProps) => {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <MotionIcon display="inline-block" animation={`${spin} infinite 2s linear`}>
          <LoadingIcon boxSize={20} />
        </MotionIcon>
      </Box>
      <Text textAlign="center" pt={2}>{`Waiting for ${whatWaitFor}`}</Text>
      <VStack pt={{ base: 4, md: 8 }}>
        <Button variant="gradientPrimary" isLoading loadingText="processing..." />
      </VStack>
    </>
  );
};
export default ProgressContent;
