import { Box, BoxProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const OnlyMobile = (props: PropsWithChildren<BoxProps>) => {
  return (
    <Box display={{ base: 'block', lg: 'none' }} {...props}>
      {props.children}
    </Box>
  );
};
