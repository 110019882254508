import { Box, HStack, Image, useDisclosure } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { Outfit } from 'next/font/google';
import { ReactElement, useState, useEffect } from 'react';
import { OnlyDesktop } from '@/components/Elements/Layout/OnlyDesktop';
import { OnlyMobile } from '@/components/Elements/Layout/OnlyMobile';
import { Footer } from '@/components/Layouts/parts/Footer';
import { Header } from '@/components/Layouts/parts/Header';
import { MobileDrawer } from '@/components/Layouts/parts/MobileDrawer';
/**
 * ダッシュボードのレイアウトを取得する
 * レイアウトごとに異なる部分を動的にプロパティで受け取る設計
 */

function getDashboardLayout() {
  // eslint-disable-next-line react/display-name
  return (page: ReactElement) => <DashboardLayout page={page}></DashboardLayout>;
}
const P5Gradation = dynamic(() => import('@/components/Elements/P5Animation'), { ssr: false });

const outfit = Outfit({ subsets: ['latin'], weight: ['400', '500', '700', '900'] });
const DashboardLayout = ({ page }: { page: ReactElement }) => {
  /**
   * ヘッダーからDrawerを開閉できるために、グローバル状態管理ライブラリは一旦入れずにレイアウトで管理することにした
   */
  const { isOpen, onClose } = useDisclosure();
  const [isP5GradationVisible, setIsP5GradationVisible] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setIsP5GradationVisible(window.innerWidth > 820);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    // TODO: カラーはテーマを参照するように
    <HStack className={`${outfit.className}`} h="calc(100vh)" gap={0} w="full">
      <OnlyDesktop h="full"></OnlyDesktop>
      <OnlyMobile h="full">
        <MobileDrawer isOpen={isOpen} onClose={onClose}></MobileDrawer>
      </OnlyMobile>
      {isP5GradationVisible && <P5Gradation />}
      <Box width="100%" height="100%" position="fixed" top="0" left="0" zIndex="-2">
        <Image h="100%" src="/images/pages/background.png" />
      </Box>
      <Box
        position="relative"
        h="full"
        flex={1}
        overflow="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        <Header />
        <Box position="relative" maxW="1042px" height="auto" w="full" pt={{ base: 8, md: 20 }}>
          {page}
        </Box>
        <Footer />
      </Box>
    </HStack>
  );
};

export { getDashboardLayout };
