import { Button, ButtonProps } from '@chakra-ui/react';

type SecondaryButtonProps = {
  label: string;
  onClick: () => void;
} & ButtonProps;

const SecondaryButton = ({ label, onClick, ...rest }: SecondaryButtonProps) => {
  return (
    <Button
      variant="outline"
      width="100%"
      h={14}
      minWidth="120px"
      fontSize={16}
      fontWeight={700}
      lineHeight={1}
      p={{ base: 2, md: 4 }}
      borderRadius={18}
      letterSpacing={1}
      color="brand.primaryBlue"
      transition="background 0.3s, color 0.3s"
      _hover={{
        background: 'brand.primaryBlue',
        color: 'brand.staticWhite',
      }}
      onClick={onClick}
      {...rest}
    >
      {label}
    </Button>
  );
};
export default SecondaryButton;
