// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const WalletIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={19.2}
    viewBox="0 0 24 19.2"
    {...props}
  >
    <path
      fill="#4b4b4b"
      d="M4.8 19.2a4.622 4.622 0 0 1-3.39-1.41A4.622 4.622 0 0 1 0 14.4V4.8a4.622 4.622 0 0 1 1.41-3.39A4.622 4.622 0 0 1 4.8 0h14.4a4.622 4.622 0 0 1 3.39 1.41A4.622 4.622 0 0 1 24 4.8v9.6a4.622 4.622 0 0 1-1.41 3.39 4.622 4.622 0 0 1-3.39 1.41Zm0-14.1h14.4a4.876 4.876 0 0 1 1.635.27 4.293 4.293 0 0 1 1.365.78V4.8a2.9 2.9 0 0 0-.87-2.13 2.9 2.9 0 0 0-2.13-.87H4.8a2.9 2.9 0 0 0-2.13.87A2.9 2.9 0 0 0 1.8 4.8v1.35a4.293 4.293 0 0 1 1.365-.78A4.876 4.876 0 0 1 4.8 5.1ZM1.89 9.18l14.31 3.45a.91.91 0 0 0 .435.015.715.715 0 0 0 .375-.2l4.8-4.02a3.023 3.023 0 0 0-1.08-1.11 2.885 2.885 0 0 0-1.53-.42H4.8a2.9 2.9 0 0 0-1.86.645 2.918 2.918 0 0 0-1.05 1.64Z"
    />
  </Icon>
);
