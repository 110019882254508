import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export type Props = PropsWithChildren<FlexProps>;

export const SafeAreaComponent: FC<Props> = ({ children, ...rest }) => (
  <Flex
    flex={1.0}
    direction="column"
    width="100%"
    height="100%"
    pb="env(safe-area-inset-bottom)"
    pt="env(safe-area-inset-top)"
    pl="env(safe-area-inset-left)"
    pr="env(safe-area-inset-right)"
    {...rest}
  >
    {children}
  </Flex>
);
