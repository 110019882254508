import { Flex, FlexProps, Text } from '@chakra-ui/react';
// import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren } from 'react';

export type Props = PropsWithChildren<FlexProps>;

export const Footer: FC = () => {
  //   const { t } = useTranslation('common');

  return (
    <Flex flexDir="row" justifyContent="center" w="100%" mt="auto" py={9}>
      {/* <Text fontSize="cap">{t('common.components.elements.footer.copyright')}</Text> */}
      <Text py={4} color="brand.staticWhite" fontSize="cap">© 2024 Alice</Text>
    </Flex>
  );
};
