import { Box, BoxProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const OnlyDesktop = (props: PropsWithChildren<BoxProps>) => {
  return (
    <Box display={{ base: 'none', lg: 'block' }} {...props}>
      {props.children}
    </Box>
  );
};
