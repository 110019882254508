import NextHead from 'next/head';

import { DEFAULT_META_DATA, PUBLIC_URL } from '@/config';

export type HeadProps = {
  title?: string;
  image?: string;
  description?: string;
  path?: string;
  twitterCard?: string;
};

export const Head = ({
  title,
  image = `${process.env.NEXT_PUBLIC_API_BASE_URL}images/ogp/ogp.png`,
  description = DEFAULT_META_DATA.desc,
  twitterCard = 'summary_large_image',
}: HeadProps = {}) => {
  const publicUrl = PUBLIC_URL[process.env.NODE_ENV];
  const ogTitle = title ? `${title} | ${DEFAULT_META_DATA.title}` : `${DEFAULT_META_DATA.title}`;
  const imageUrl = image || `${publicUrl}/${DEFAULT_META_DATA.image}`;

  return (
    <NextHead>
      <title>{ogTitle}</title>
      <meta property="og:url" content={publicUrl} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:description" content={description} />
      <meta property="twitter:card" content={twitterCard} />
      <meta property="twitter:image" content={imageUrl} />
      <meta property="twitter:creator" content="@Alice_slash_NFT" />
    </NextHead>
  );
};
