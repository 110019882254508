// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const SuccessIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={90} height={90} viewBox="0 0 90 90" {...props}>
    <defs>
      <clipPath id="success_svg__a">
        <path fill="#43d866" stroke="#707070" d="M651 2887.235h90v90h-90z" />
      </clipPath>
    </defs>
    <g clipPath="url(#success_svg__a)" transform="translate(-651 -2887.235)">
      <path
        fill="#43d866"
        d="M716.076 2917.789a3.527 3.527 0 0 1 0 4.987l-23.648 23.658a3.527 3.527 0 0 1-4.987 0l-11.26-11.26a3.531 3.531 0 0 1 4.987-4.987l8.766 8.766 21.155-21.164a3.527 3.527 0 0 1 4.987 0Zm25.182 14.317a45.106 45.106 0 1 1 0-.045Zm-7.051 0a38.055 38.055 0 1 0 0 .045Z"
      />
    </g>
  </Icon>
);
