import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

import { SafeAreaComponent } from './SafeArea';

export type WrapperProps = FlexProps &
  PropsWithChildren<{
    contentProps?: FlexProps;
  }>;

export const WrapperComponent: FC<WrapperProps> = ({ children, contentProps, ...rest }) => {
  return (
    <Flex
      direction="column"
      justifyContent="align"
      minH="100vh"
      boxSizing="border-box"
      mx="auto"
      w="100%"
      maxW="1280px"
      {...rest}
    >
      <SafeAreaComponent {...contentProps}>{children}</SafeAreaComponent>
    </Flex>
  );
};
