// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const ArrowIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="35px"
    height="6px"
    viewBox="0 0 35 6"
    fill="none"
    {...props}
  >
    <path d="M1 2.5C0.723858 2.5 0.5 2.72386 0.5 3C0.5 3.27614 0.723858 3.5 1 3.5L1 2.5ZM1 3.5L31 3.5L31 2.5L1 2.5L1 3.5Z" fill="white"/>
    <path d="M35 3L30.5 5.59808L30.5 0.401924L35 3Z" fill="white"/>
  </Icon>
);