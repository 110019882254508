// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const CoinbaseLogoIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="coinbase-logo_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 2500 2500"
    {...props}
  >
    <style>{'.coinbase-logo_svg__st0{fill:none}'}</style>
    <g id="coinbase-logo_svg__Layer_x0020_1">
      <path d="M0 0h2500v2500H0z" className="coinbase-logo_svg__st0" />
      <g id="coinbase-logo_svg___1556951675616">
        <g id="coinbase-logo_svg__Layer_x0020_1_0">
          <path d="M0 0h2500v2500H0z" className="coinbase-logo_svg__st0" />
          <g id="coinbase-logo_svg___1842137537696">
            <path
              d="M1250 0c690.2 0 1250 559.8 1250 1250s-559.8 1250-1250 1250S0 1940.2 0 1250 559.8 0 1250 0z"
              style={{
                fill: '#fff',
              }}
            />
            <path
              d="M1250.4 1689.5c-242.8 0-439.4-196.7-439.4-439.5s196.7-439.4 439.4-439.4c217.5 0 398.1 158.6 432.9 366.2H2126c-37.4-451.2-414.9-805.7-875.6-805.7-485.2 0-878.9 393.7-878.9 878.9s393.7 878.9 878.9 878.9c460.7 0 838.3-354.5 875.6-805.7h-443.1c-34.8 207.7-215 366.3-432.5 366.3z"
              style={{
                fill: '#0052ff',
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
