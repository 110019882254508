import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface BasicModalLayoutProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  closeOnOverlayClick?: boolean;
}

const BasicModalLayout = ({
  children,
  title,
  isOpen,
  closeOnOverlayClick,
  onClose,
}: BasicModalLayoutProps) => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader
          fontSize={{
            base: 18,
            md: 24,
          }}
          fontWeight="bold"
          pl={{ base: 5, md: 10 }}
          pr={{ base: 5, md: 10 }}
          pt={{ base: 5, md: 10 }}
          pb={{ base: 5, md: 6 }}
        >
          {title}
        </ModalHeader>
        {closeOnOverlayClick && <ModalCloseButton />}
        <Box p={{ base: 5, md: 10 }} pt={{ base: 0, md: 0 }}>
          {children}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default BasicModalLayout;
