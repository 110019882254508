// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const MetamaskFoxIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={38} height={38} viewBox="0 0 38 38" {...props}>
    <defs>
      <clipPath id="metamask-fox_svg__a">
        <path fill="#fff" d="M799 270h38v38h-38z" data-name="\u9577\u65B9\u5F62 2581" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#metamask-fox_svg__a)"
      data-name="\u30DE\u30B9\u30AF\u30B0\u30EB\u30FC\u30D7 3"
      transform="translate(-799 -270)"
    >
      <g strokeLinecap="round" strokeLinejoin="round">
        <path
          fill="#e2761b"
          stroke="#e2761b"
          d="m834.206 272.6-14.058 10.441 2.6-6.16Z"
          data-name="\u30D1\u30B9 1773"
        />
        <g fill="#e4761b" stroke="#e4761b" data-name="\u30B0\u30EB\u30FC\u30D7 4937">
          <path d="m801.78 272.6 13.945 10.54-2.472-6.259Z" data-name="\u30D1\u30B9 1774" />
          <path
            d="m829.148 296.802-3.744 5.736 8.011 2.2 2.3-7.813Z"
            data-name="\u30D1\u30B9 1775"
          />
          <path
            d="m800.297 296.929 2.289 7.813 8.011-2.2-3.744-5.74Z"
            data-name="\u30D1\u30B9 1776"
          />
          <path
            d="m810.137 287.11-2.232 3.377 7.954.353-.283-8.548Z"
            data-name="\u30D1\u30B9 1777"
          />
          <path d="m825.85 287.11-5.51-4.917-.184 8.647 7.94-.353Z" data-name="\u30D1\u30B9 1778" />
          <path d="m810.587 302.538 4.775-2.331-4.125-3.221Z" data-name="\u30D1\u30B9 1779" />
          <path d="m820.624 300.207 4.79 2.331-.664-5.552Z" data-name="\u30D1\u30B9 1780" />
        </g>
        <g fill="#d7c1b3" stroke="#d7c1b3" data-name="\u30B0\u30EB\u30FC\u30D7 4938">
          <path
            d="m825.418 302.538-4.79-2.331.381 3.122-.042 1.314Z"
            data-name="\u30D1\u30B9 1781"
          />
          <path
            d="m810.583 302.538 4.45 2.105-.028-1.314.353-3.122Z"
            data-name="\u30D1\u30B9 1782"
          />
        </g>
        <path
          fill="#233447"
          stroke="#233447"
          d="m815.108 294.923-3.984-1.173 2.812-1.286Z"
          data-name="\u30D1\u30B9 1783"
        />
        <path
          fill="#233447"
          stroke="#233447"
          d="m820.878 294.923 1.173-2.458 2.826 1.286Z"
          data-name="\u30D1\u30B9 1784"
        />
        <g fill="#cd6116" stroke="#cd6116" data-name="\u30B0\u30EB\u30FC\u30D7 4939">
          <path d="m810.583 302.538.678-5.736-4.422.127Z" data-name="\u30D1\u30B9 1785" />
          <path d="m824.74 296.802.678 5.736 3.744-5.609Z" data-name="\u30D1\u30B9 1786" />
          <path
            d="m828.102 290.487-7.94.353.735 4.083 1.173-2.458 2.826 1.286Z"
            data-name="\u30D1\u30B9 1787"
          />
          <path
            d="m811.12 293.751 2.826-1.286 1.159 2.458.749-4.083-7.955-.353Z"
            data-name="\u30D1\u30B9 1788"
          />
        </g>
        <g fill="#e4751f" stroke="#e4751f" data-name="\u30B0\u30EB\u30FC\u30D7 4940">
          <path d="m807.898 290.487 3.334 6.5-.113-3.235Z" data-name="\u30D1\u30B9 1789" />
          <path d="m824.894 293.751-.141 3.236 3.348-6.5Z" data-name="\u30D1\u30B9 1790" />
          <path d="m815.853 290.84-.749 4.083.932 4.818.212-6.344Z" data-name="\u30D1\u30B9 1791" />
          <path d="m820.161 290.84-.381 2.543.17 6.358.947-4.818Z" data-name="\u30D1\u30B9 1792" />
        </g>
        <path
          fill="#f6851b"
          stroke="#f6851b"
          d="m820.893 294.923-.947 4.818.678.466 4.126-3.221.141-3.235Z"
          data-name="\u30D1\u30B9 1793"
        />
        <path
          fill="#f6851b"
          stroke="#f6851b"
          d="m811.123 293.75.113 3.235 4.126 3.221.678-.466-.932-4.818Z"
          data-name="\u30D1\u30B9 1794"
        />
        <path
          fill="#c0ad9e"
          stroke="#c0ad9e"
          d="m820.967 304.643.042-1.314-.353-.311h-5.326l-.325.311.028 1.314-4.45-2.105 1.554 1.272 3.151 2.19h5.411l3.165-2.19 1.554-1.272Z"
          data-name="\u30D1\u30B9 1795"
        />
        <path
          fill="#161616"
          stroke="#161616"
          d="m820.628 300.207-.678-.466h-3.914l-.678.466-.353 3.122.325-.311h5.326l.353.311Z"
          data-name="\u30D1\u30B9 1796"
        />
        <g fill="#763d16" stroke="#763d16" data-name="\u30B0\u30EB\u30FC\u30D7 4941">
          <path
            d="m834.799 283.719 1.2-5.764-1.793-5.355-13.592 10.088 5.228 4.422 7.389 2.162 1.639-1.907-.706-.509 1.13-1.031-.876-.678 1.13-.862Z"
            data-name="\u30D1\u30B9 1797"
          />
          <path
            d="m800 277.955 1.2 5.764-.763.565 1.13.862-.862.678 1.13 1.031-.706.509 1.625 1.907 7.389-2.162 5.228-4.422L801.78 272.6Z"
            data-name="\u30D1\u30B9 1798"
          />
        </g>
        <path
          fill="#f6851b"
          stroke="#f6851b"
          d="m833.231 289.272-7.391-2.162 2.246 3.377-3.348 6.5 4.408-.057h6.57Z"
          data-name="\u30D1\u30B9 1799"
        />
        <path
          fill="#f6851b"
          stroke="#f6851b"
          d="m810.145 287.11-7.389 2.162-2.459 7.657h6.556l4.394.057-3.334-6.5Z"
          data-name="\u30D1\u30B9 1800"
        />
        <path
          fill="#f6851b"
          stroke="#f6851b"
          d="m820.162 290.84.466-8.152 2.148-5.807h-9.537l2.119 5.807.494 8.152.17 2.571.014 6.33h3.914l.028-6.33Z"
          data-name="\u30D1\u30B9 1801"
        />
      </g>
    </g>
  </Icon>
);
