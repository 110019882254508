import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { DiscordIcon } from '@/components/Elements/Link/icons/DiscordIcon';
import { HomePageIcon } from '@/components/Elements/Link/icons/HomePageIcon';
import { OpenseaIcon } from '@/components/Elements/Link/icons/OpenseaIcon';
import { XIcon } from '@/components/Elements/Link/icons/XIcon';

export const HeaderLinkWrapper: FC = () => {
  return (
    <Flex w="150px" justifyContent="space-between" cursor="pointer" color="brand.staticWhite">
      <HomePageIcon />
      <XIcon />
      <DiscordIcon />
      <OpenseaIcon />
    </Flex>
  );
};
