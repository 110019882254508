import { Button, ButtonProps, Text } from '@chakra-ui/react';
import Decimal from 'decimal.js';
import { forwardRef } from 'react';
import { FetchBalanceResult } from 'wagmi/dist/actions';

export const HeaderMenuButton = forwardRef(
  (props: ButtonProps & { address: string; balancedata?: FetchBalanceResult }, ref) => (
    <Button
      ref={ref}
      bg="brand.staticGray.500"
      color="brand.staticWhite"
      pl={4}
      pr={2}
      py={4}
      ml={4}
      fontWeight="semibold"
      fontSize="xs"
      borderRadius={8}
      borderWidth={0}
      w="auto"
      _hover={{}}
      {...props}
    >
      <Text as="span" fontSize="md">
        {props.balancedata?.formatted &&
          new Decimal(props.balancedata.formatted).toFixed(4, Decimal.ROUND_FLOOR)}
        <Text as="span" fontSize="xs" ml={1}>
          {props.balancedata?.symbol}
        </Text>
      </Text>
      <Text
        // TODO: register this bg as variant
        bgGradient="linear(to-r, brand.purple.100, brand.pink)"
        borderRadius={4}
        ml={4}
        px={2}
        py={1}
      >
        {props.address}
      </Text>
    </Button>
  )
);
