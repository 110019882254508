// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const WalletConnectIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={38} height={38} viewBox="0 0 38 38" {...props}>
    <defs>
      <clipPath id="wallet-connect_svg__a">
        <path fill="#fff" d="M-.134-5.314h38v38h-38z" data-name="\u9577\u65B9\u5F62 2582" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#wallet-connect_svg__a)"
      data-name="\u30DE\u30B9\u30AF\u30B0\u30EB\u30FC\u30D7 4"
      transform="translate(.134 5.314)"
    >
      <path
        fill="#3b99fc"
        d="M7.763 7.048a15.94 15.94 0 0 1 22.205 0l.738.723a.757.757 0 0 1 0 1.087l-2.524 2.471a.4.4 0 0 1-.555 0l-1.016-.994a11.12 11.12 0 0 0-15.491 0l-1.092 1.064a.4.4 0 0 1-.555 0l-2.52-2.471a.757.757 0 0 1 0-1.087Zm27.426 5.111 2.247 2.2a.757.757 0 0 1 0 1.087l-10.131 9.919a.8.8 0 0 1-1.11 0l-7.19-7.04a.2.2 0 0 0-.278 0l-7.19 7.04a.8.8 0 0 1-1.11 0L.296 15.446a.757.757 0 0 1 0-1.087l2.247-2.2a.8.8 0 0 1 1.11 0l7.19 7.04a.2.2 0 0 0 .278 0l7.19-7.04a.8.8 0 0 1 1.11 0l7.19 7.04a.2.2 0 0 0 .278 0l7.19-7.04a.8.8 0 0 1 1.11 0Z"
      />
    </g>
  </Icon>
);
