import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
} from '@chakra-ui/react';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useAccount, useBalance, useDisconnect, useNetwork } from 'wagmi';
import { HeaderMenuButton } from './parts/HeaderMenuButton';
import { HeaderLinkWrapper } from '@/components/Layouts/parts/Header/parts/HeaderLinkWrapper';
import { SwitchLanguageButton } from '@/components/Layouts/parts/Header/parts/SwitchLanguageButton';
import ConnectModal from '@/components/Modal/Connect';
import { network } from '@/constants/network';
import { useClientReady } from '@/features/common/hooks/useClientReady';

export const Header = () => {
  const NetworkIcon = network.icon;
  const { address, isConnected } = useAccount();
  const { data: balanceData } = useBalance({
    address,
  });
  const { disconnectAsync } = useDisconnect();
  const { chain } = useNetwork();
  const router = useRouter();
  const formattedAddress = address
    ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
    : '';
  const { t } = useTranslation(['common']);
  const isClientReady = useClientReady();
  const {
    isOpen: isOpenConnectModal,
    onOpen: onOpenConnectModal,
    onClose: onCloseConnectModal,
  } = useDisclosure();
  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu } = useDisclosure();

  const onDisconnect = async () => {
    await disconnectAsync();
    router.replace('/');
  };

  return (
    <HStack
      position="sticky"
      top={0}
      left={0}
      py={{ base: 3, md: 8 }}
      pl={8}
      pr={{ base: 5, md: 8 }}
      alignItems="center"
      zIndex={{ base: 1500, md: 1 }}
      w="full"
    >
      <Drawer placement="right" onClose={onCloseMenu} isOpen={isOpenMenu}>
        <DrawerOverlay />
        <DrawerContent maxW="264px">
          <DrawerBody pt={24} background="brand.offWhite">
            <Flex alignItems="center">
              <SwitchLanguageButton />
              <HeaderLinkWrapper />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex flexDir="row" alignItems="center" justifyContent="flex-end" w="100%">
        <Flex flexDir="row" alignItems="center" justifyContent="flex-end" w="auto">
          <Flex display={{ base: 'none', md: 'flex' }} flexDir="row" py={1} w="auto">
            <HeaderLinkWrapper />
          </Flex>
          <Flex display={{ base: 'none', md: 'flex' }} flexDir="row" px={6} py={1} w="auto">
            <SwitchLanguageButton />
          </Flex>
          <Flex alignItems="center" w="auto" pr={{ base: '20px', md: '0' }}>
            {isClientReady &&
              (isConnected ? (
                <>
                  <Flex
                    flexDir="row"
                    px={{ base: '0.6rem', md: 4 }}
                    py={2}
                    borderRadius={16}
                    w={{ base: '110px', md: 'auto' }}
                    alignItems="center"
                    bgGradient="linear(to-r, brand.purple.100, brand.pink)"
                    ml="2px"
                  >
                    {chain?.id === network.chainId ? (
                      <NetworkIcon boxSize={4} />
                    ) : (
                      <WarningTwoIcon boxSize={4} />
                    )}

                    <Text ml={2} fontWeight="semibold" fontSize="xs" color="brand.staticWhite">
                      {chain?.id === network.chainId
                        ? network.name
                        : t('common.networks.unsupported.name')}
                    </Text>
                  </Flex>

                  <Menu>
                    <MenuButton
                      as={HeaderMenuButton}
                      address={formattedAddress}
                      balancedata={balanceData}
                      px={{ base: 4, md: 2 }}
                      ml={{ base: '8px', md: 4 }}
                    />
                    <MenuList>
                      <MenuItem onClick={onDisconnect}>
                        {t('common.components.elements.buttons.disconnect')}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              ) : (
                <Button
                  size="xs"
                  bg="none"
                  borderColor="brand.staticWhite"
                  color="brand.gray.0"
                  px={6}
                  py={4}
                  borderRadius={16}
                  onClick={onOpenConnectModal}
                >
                  {t('common.components.elements.buttons.connect')}
                </Button>
              ))}

            <Text
              as="span"
              cursor="pointer"
              display={{ base: 'block', md: 'none' }}
              w={isOpenMenu ? '30px' : '18px'}
              h={isOpenMenu ? '30px' : '9px'}
              position="absolute"
              right="12px"
              zIndex="9999"
              onClick={isOpenMenu ? onCloseMenu : onOpenMenu}
            >
              <Text
                as="span"
                display="inline-block"
                transition="all 0.4s"
                position="absolute"
                top={isOpenMenu ? '22px' : 0}
                right="0"
                margin="0"
                height="1px"
                background={isOpenMenu ? 'linear-gradient(135deg,#fc2b60 0,#db9e37 100%)' : '#fff'}
                transform={isOpenMenu ? 'translateY(-6px) rotate(45deg)' : 'none'}
                w="100%"
              ></Text>
              <Text
                as="span"
                display="inline-block"
                transition="all 0.4s"
                position="absolute"
                bottom={isOpenMenu ? '20px' : 0}
                right="0"
                margin="0"
                height="1px"
                background={isOpenMenu ? 'linear-gradient(135deg,#fc2b60 0,#db9e37 100%)' : '#fff'}
                transform={isOpenMenu ? 'translateY(6px) rotate(-45deg)' : 'none'}
                w="100%"
              ></Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isClientReady && <ConnectModal isOpen={isOpenConnectModal} onClose={onCloseConnectModal} />}
    </HStack>
  );
};
