// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const LoadingIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={91} height={90} viewBox="0 0 91 90" {...props}>
    <defs>
      <clipPath id="loading_svg__a">
        <path fill="#595862" stroke="#707070" d="M-.362-.424h91v90h-91z" />
      </clipPath>
    </defs>
    <g clipPath="url(#loading_svg__a)" transform="translate(.361 .424)">
      <g fill="#595862">
        <path d="M46.394 86.673a1.5 1.5 0 0 1-1.382 1.6h-.067a1.475 1.475 0 0 1-1.449-1.517V61.891a1.5 1.5 0 0 1 1.382-1.6h.067a1.475 1.475 0 0 1 1.449 1.517v24.865ZM89.882 43.478a1.369 1.369 0 0 1-1.281 1.449h-24.8a1.369 1.369 0 0 1-1.449-1.281v-.169a1.369 1.369 0 0 1 1.281-1.449h24.8a1.369 1.369 0 0 1 1.449 1.281ZM27.557 43.478a1.369 1.369 0 0 1-1.281 1.449H1.457a1.369 1.369 0 0 1-1.449-1.281v-.169a1.369 1.369 0 0 1 1.281-1.449h24.8a1.369 1.369 0 0 1 1.449 1.281v.169ZM59.145 31.301a1.541 1.541 0 0 1-2.179-2.179l17.536-17.537a1.541 1.541 0 0 1 2.179 2.179ZM15.377 75.069a1.541 1.541 0 0 1-2.179-2.179l17.536-17.535a1.541 1.541 0 0 1 2.179 2.179ZM56.977 57.532a1.541 1.541 0 0 1 2.179-2.177L76.693 72.89a1.541 1.541 0 0 1-2.179 2.179ZM13.198 13.618a1.39 1.39 0 0 1-.045-1.977l.045-.045a1.574 1.574 0 0 1 2.179 0l17.537 17.536a1.541 1.541 0 0 1-2.179 2.179L13.198 13.629ZM53.494 26.66a1.593 1.593 0 0 1-2.033.865 1.614 1.614 0 0 1-.865-2.033l9.425-22.906a1.563 1.563 0 1 1 2.9 1.168L53.494 26.66ZM29.868 84.056a1.563 1.563 0 0 1-2.9-1.168l9.427-22.907a1.563 1.563 0 0 1 2.9 1.168ZM61.616 51.736a1.563 1.563 0 0 1 1.168-2.9l22.907 9.43a1.563 1.563 0 1 1-1.168 2.9ZM4.21 28.11a1.563 1.563 0 0 1 1.168-2.9l22.906 9.571a1.563 1.563 0 1 1-1.168 2.9L4.21 28.11ZM62.627 37.536a1.6 1.6 0 0 1-2.033-.865 1.545 1.545 0 0 1 .843-2.022h.034l22.906-9.583a1.545 1.545 0 0 1 2.022.843v.034a1.531 1.531 0 0 1-.831 2.022h-.034l-22.907 9.582ZM5.524 61.457a1.545 1.545 0 0 1-2.022-.843v-.037a1.531 1.531 0 0 1 .831-2.02h.034l22.906-9.585a1.6 1.6 0 0 1 2.033.865 1.545 1.545 0 0 1-.843 2.022h-.034L5.524 61.442ZM50.742 61.015a1.6 1.6 0 0 1 .865-2.033 1.529 1.529 0 0 1 2.011.843v.036l9.583 22.9a1.545 1.545 0 0 1-.843 2.022h-.034a1.515 1.515 0 0 1-2.011-.831v-.031l-9.582-22.906ZM26.824 3.766a1.545 1.545 0 0 1 .843-2.022h.034a1.535 1.535 0 0 1 2.022.82l.022.045 9.425 22.906a1.545 1.545 0 0 1-.843 2.022h-.034a1.515 1.515 0 0 1-2.011-.831v-.029L26.833 3.766Z" />
      </g>
    </g>
  </Icon>
);
