import { VStack, Button, Text } from '@chakra-ui/react';
import { disconnect } from '@wagmi/core';
import { useTranslation } from 'next-i18next';
import { useAccount, useConnect } from 'wagmi';
import { CoinbaseLogoIcon, MetamaskFoxIcon, WalletConnectIcon } from '@/components/Icons/wallets';
import { BasicModalLayout } from '@/components/Modal/Parts';
import { useEthereumInjected } from '@/features/common/hooks/useEthereumInjected';

interface ConnectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConnectModal = ({ isOpen, onClose }: ConnectModalProps) => {
  const { isConnected } = useAccount();
  const { pendingConnector, connectors, isLoading, connectAsync } = useConnect();
  const { t } = useTranslation('common');
  const isInjectedEthereum = useEthereumInjected();

  const onConnect = async (connectorId: 'metaMask' | 'walletConnect' | 'coinbaseWallet') => {
    try {
      if (isConnected) {
        await disconnect();
      }

      const connector = connectors.find((connectorItem) => connectorItem.id === connectorId);

      if (!connector) {
        return;
      }

      await connectAsync({
        connector,
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <BasicModalLayout
      title={t('common.components.elements.buttons.connect')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack spacing={4}>
        <Button
          display={isInjectedEthereum ? 'flex' : 'none'}
          variant="gradientPrimary"
          onClick={() => onConnect('metaMask')}
          py={6}
          px={4}
          h={20}
          leftIcon={<MetamaskFoxIcon boxSize={9} />}
          maxH={16}
          isLoading={isLoading && pendingConnector?.id === 'metaMask'}
          isDisabled={isLoading}
        >
          <Text as="span">{t('common.components.elements.buttons.metaMask')}</Text>
        </Button>
        <Button
          variant="gradientPrimary"
          onClick={() => onConnect('coinbaseWallet')}
          py={6}
          px={4}
          h={20}
          leftIcon={<CoinbaseLogoIcon boxSize={9} />}
          maxH={16}
          isLoading={isLoading && pendingConnector?.id === 'coinbaseWallet'}
          isDisabled={isLoading}
        >
          <Text as="span">{t('common.components.elements.buttons.coinbase')}</Text>
        </Button>
        <Button
          variant="gradientPrimary"
          onClick={() => onConnect('walletConnect')}
          py={6}
          px={4}
          h={20}
          leftIcon={<WalletConnectIcon boxSize={9} />}
          maxH={16}
          isLoading={isLoading && pendingConnector?.id === 'walletConnect'}
          isDisabled={isLoading}
        >
          <Text as="span">{t('common.components.elements.buttons.walletConnect')}</Text>
        </Button>
      </VStack>
    </BasicModalLayout>
  );
};

export default ConnectModal;
