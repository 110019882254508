// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const LinkIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={10.203}
    viewBox="0 0 12 10.203"
    {...props}
  >
    <path
      fill="#77758a"
      d="M9.217 10.2H.58A.581.581 0 0 1 0 9.62V2.812a.581.581 0 0 1 .58-.58h8.638a.581.581 0 0 1 .58.58v6.812a.581.581 0 0 1-.58.58V2.812H.58v6.812h8.638ZM2.493.58h8.927v7.1H12V.58a.581.581 0 0 0-.58-.58H2.493Z"
    />
  </Icon>
);
