// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const DashboardIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={23} height={23} viewBox="0 0 23 23" {...props}>
    <path
      fill="#f2f2f3"
      d="M3.499 19.158v-1.156l.727-.727v1.882h-.727Zm3.807 0v-4.916l.727-.727v5.647Zm3.85 0V13.51l.727.727v4.92Zm3.807 0v-4.916l.727-.727v5.647Zm3.807 0v-8.685l.729-.731v9.412ZM3.499 13.297v-.984l6.118-6.118 3.765 3.765 6.117-6.118v1.027l-6.118 6.118-3.764-3.765Z"
    />
    <path fill="none" d="M0 0h23v23H0z" />
  </Icon>
);
