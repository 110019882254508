// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const ErrorIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={90} height={90} viewBox="0 0 90 90" {...props}>
    <defs>
      <clipPath id="error_svg__a">
        <path fill="#f75c68" stroke="#707070" d="M843 2887h90v90h-90z" />
      </clipPath>
    </defs>
    <g clipPath="url(#error_svg__a)" transform="translate(-843 -2887)">
      <path
        fill="#f75c68"
        d="M888 2887a45 45 0 1 0 45 45 45 45 0 0 0-45-45Zm0 84.375A39.375 39.375 0 1 1 927.375 2932 39.375 39.375 0 0 1 888 2971.375Zm16.05-51.446L891.982 2932l12.068 12.071a2.81 2.81 0 1 1-3.836 4.106l-.135-.135-12.068-12.071-12.082 12.079a2.81 2.81 0 1 1-4.106-3.836l.135-.135 12.071-12.068-12.079-12.082a2.81 2.81 0 0 1 3.971-3.971l12.068 12.071 12.071-12.072a2.81 2.81 0 0 1 3.971 3.971Z"
      />
    </g>
  </Icon>
);
