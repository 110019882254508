import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC , useState } from 'react';
export const SwitchLanguageButton: FC = () => {
  const router = useRouter();
  const [language, setLanguage] = useState(router.locale === 'en' ? '日本語' : 'English');

  const onClickTranslation = () => {
    const nextLocale = router.locale === 'en' ? 'ja' : 'en';
    const { pathname, asPath, query } = router;
    setLanguage(router.locale === 'en' ? 'English' : '日本語');
    router.replace({ pathname, query }, asPath, {
      locale: nextLocale,
    });
  };

  return (
    <>
      <Box cursor="pointer" w={{base: "7rem", md: "auto"}} color={{base: "#6a2e44", md: "brand.staticWhite"}} onClick={onClickTranslation}>
        {language}
      </Box>
    </>
  );
};
