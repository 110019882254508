// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const CheckIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="28px"
    height="28px"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#1C7A59"/>
    <g clipPath="url(#clip0_831_2707)">
      <path d="M11.489 20.6363C11.0064 20.6365 10.5437 20.4447 10.2027 20.1032L6.31383 16.2158C5.89539 15.7972 5.89539 15.1187 6.31383 14.7001C6.73242 14.2816 7.41093 14.2816 7.82951 14.7001L11.489 18.3596L21.1705 8.67809C21.5891 8.25965 22.2676 8.25965 22.6862 8.67809C23.1046 9.09667 23.1046 9.77519 22.6862 10.1938L12.7753 20.1032C12.4344 20.4447 11.9716 20.6365 11.489 20.6363Z" fill="white"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_831_2707" x1="13.8783" y1="2.49318e-05" x2="27.3456" y2="17.825" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C53FE0"/>
        <stop offset="1" stopColor="#EA3E94"/>
      </linearGradient>
      <clipPath id="clip0_831_2707">
        <rect width="17" height="17" fill="white" transform="translate(6 6)"/>
      </clipPath>
    </defs>
  </Icon>
);
