/**
 * 設定ファイル
 * .envの内容、設定を変数に格納する
 */

import { Chain } from '@/types';

/*
|--------------------------------------------------------------------------
| Google Analytics ID
|--------------------------------------------------------------------------
|
| The id for the Google Analytics.
|
*/
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string;

/*
|--------------------------------------------------------------------------
| Firebase configuration
|--------------------------------------------------------------------------
|
| The configuration for the Client Firebase App.
|
*/
export const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY as string,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID as string,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID as string,
};
export const ADMIN = {
  projectId: process.env.NEXT_PUBLIC_FIREBASE_ADMIN_PROJECT_ID as string,
  clientEmail: process.env.NEXT_PUBLIC_FIREBASE_ADMIN_CLIENT_EMAIL as string,
  privateKey: process.env.NEXT_PUBLIC_FIREBASE_ADMIN_PRIVATE_KEY as string,
};

/*
|--------------------------------------------------------------------------
| Signature Message configuration
|--------------------------------------------------------------------------
|
| The configuration for the signature.
|
*/
export const SIGNATURE_MESSAGE = 'Welcome to Our Project!!\n\n Please sign in';

/*
|--------------------------------------------------------------------------
| Infura API Key
|--------------------------------------------------------------------------
|
| The key for the Infura API.
|
*/
export const INFURA_API_KEY = process.env.NEXT_PUBLIC_INFURA_API_KEY as string;

/*
|--------------------------------------------------------------------------
| URL configuration
|--------------------------------------------------------------------------
|
| The configuration for the public URL.
|
*/
export const PUBLIC_URL: { [Key in NodeJS.ProcessEnv['NODE_ENV']]: string } = {
  development: 'http://localhost:3000',
  test: 'http://localhost:3000',
  production: process.env.NEXT_PUBLIC_API_BASE_URL as string,
} as const;

/*
|--------------------------------------------------------------------------
| SEO configuration
|--------------------------------------------------------------------------
|
| The configuration for the SEO. Make sure to go through all
| the config properties to make default meta tag.
|
*/
export const DEFAULT_META_DATA = {
  title: 'Alice Bridge',
  desc: 'A website for Alice NFT Bridge. Alice is the corner of the internet where art, community, and culture merge to bring creative ideas to life.',
  image: 'OGP.png',
} as const;

/*
|--------------------------------------------------------------------------
| Chain configuration
|--------------------------------------------------------------------------
|
| The configuration for the Chain.
|
*/
export const CHAIN_ID = {
  POLYGON: 137,
  MUMBAI: 80001,
  ASTAR: 592,
} as const;

export const CHAIN: { [key in number]: Chain } = {
  137: {
    name: 'Polygon',
    icon: '/icon/polygon.svg',
    symbol: 'MATIC',
  },
  80001: {
    name: 'Mumbai',
    icon: '/icon/polygon.svg',
    symbol: 'MATIC',
  },
  592: {
    name: 'Astar',
    icon: '/icon/astar.svg',
    symbol: 'ASTR',
  },
} as const;

/*
|--------------------------------------------------------------------------
| ReCaptcha Key
|--------------------------------------------------------------------------
|
| The key for the ReCaptcha. Make sure to go through all
| the config properties to make keep firebase app secure.
|
*/
export const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string;

/*
|--------------------------------------------------------------------------
| Debug Token Flag
|--------------------------------------------------------------------------
|
| The flag for the firebase app check debug token. Make sure to go through all
| the flag to make access token only local app.
|
*/
export const FIREBASE_APPCHECK_DEBUG_TOKEN = process.env
  .NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN as string;

/*
|--------------------------------------------------------------------------
| Static path configuration
|--------------------------------------------------------------------------
|
| The configuration for the static paths.
|
*/
export const STATIC_PATH = {
  contact: '', // Google Form URL
  customer_support: '',
};
