import { Box } from '@chakra-ui/react';

interface ExplorerButtonProps {
  explorerUrl: string;
}

const ExplorerButton = ({ explorerUrl }: ExplorerButtonProps) => {
  return (
    <Box
      as="button"
      width="100%"
      minWidth="120px"
      fontSize={16}
      fontWeight={700}
      lineHeight={1}
      p={{ base: 2, md: 4 }}
      border="1px solid"
      borderColor="brand.primaryBlue"
      borderRadius={8}
      letterSpacing={1}
      color="brand.primaryBlue"
      transition="background 0.3s, color 0.3s"
      _hover={{
        background: 'brand.primaryBlue',
        color: 'brand.staticWhite',
      }}
      onClick={() => {
        // open explorer url
        window.open(explorerUrl, '_blank', 'noopener noreferrer');
      }}
    >
      View on Explorer
    </Box>
  );
};

export default ExplorerButton;
